export const dataProcess = [
  {
    accHeader: "Adquisición de información",
    accBody:
      "Reunión con el cliente para obtener información de la empresa, como filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes. Definición de objetivos generales del proyecto.",
  },
  {
    accHeader: "Análisis de la competencia y matriz FODA",
    accBody: "Adquisición de información reelevante sobre competencia para posterior analisis y comparación para conocer fortalezas, oportunidades, debilidades y amenazas de la empresa. ",
  },
  {
    accHeader: "Integración de herramientas de medidicón",
    accBody:
      "Integración de CrazyEgg y Google Analytics (herramientas para medir la usabilidad del sitio web) para recopilación de datos durante una semana.",
  },
  {
    accHeader: "Análisis de comportamiento de usuarios",
    accBody:
      "Estudio de información obtenida con CrazyEgg y Analytics. Resumen de resultados.",
  },
  {
    accHeader: "Arquitectura de la información de la web",
    accBody: "Clasificiación, jerarquización y etiquetación de la información de la web.",
  },
  {
    accHeader: "Creación de mapa de navegación",
    accBody: "Creción de nueva mapa de navegación (navbar, sidebar, etc) teniendo en cuenta la información obtenida.",
  },
  {
    accHeader: "Diseño de wireframe",
    accBody: "Bocetación de nueva interfaz para análisis preeliminar de la nueva navegación, jerarquía y organización de elementos. ",
  },
  {
    accHeader: "Prototipado inicial y testeo personal",
    accBody: "Prueba inicial de nueva navegación para ajustar detalles.",
  },
  {
    accHeader: "Diseño de elementos",
    accBody: "Creación de paleta de tipografías, colores, diseño de iconos, banners y otras imágenes",
  },
  {
    accHeader: "Diseño de Interfaz",
    accBody: "Diseño de interfaz utilizando los elementos previamente diseñados en base al wireframe previamente realizado.",
  },
  {
    accHeader: "Prototipado preliminar",
    accBody: "Diseño de prototipado completo para posterior testeo de usuarios.",
  },
  {
    accHeader: "Testeo de usuario",
    accBody: "Grabación de sesiones de usuarios con la nueva interfaz para obtener datos de comportamiento y comentarios.",
  },
  {
    accHeader: "Ajustes y preparación de archivos",
    accBody: "Ajustes (en caso de ser necesarios, repetición de testeo y ajustes hasta lograr objetivos). Preparación de archivos del diseño para entregar al programador.",
  },
]

export const dataItem = [
  {
    itemTitle: "Líneas de diseño",
    itemDesc:
      "Buscando alternativas para el isotipo se trabajo sobre conceptos que abarcaran los servicios de la empresa de rastreo y monitoreo, combinados en el primer caso con un aguila como simbolo de seguridad y vigilancia y en el segundo con un toro enojado, como simbolo de seguridad y a su vez de ganado.",
  },
  {
    itemTitle: "Concepto isotipo",
    itemDesc:
      "Para representarlo se escogió realizar una figura geométrica que hace referencia a el toro, la ruta, el símbolo de ubicación y un escudo. La forma que combina rectas y curvas tiene terminaciones en punta que trasmiten agresividad, se perciben a simple vista dos cuernos que representan al toro y a su vez también dos rutas por su línea blanca central. Su forma también remite a un símbolo de ubicación por su parte superior curva casi circular, y su terminación en punta hacia abajo, que también hace que se asemeje a un escudo.",
  },
  {
    itemTitle: "Color",
    itemDesc:
      "Los colores utilizados para la marca son el rojo en representación del toro, la carne y como señal de alerta, debido a que simboliza el peligro. El rojo escogido tiene un poco mas de magenta que de amarillo y es un poco desaturado, lo que le da un toque de originaidad y hace que combine a la perfección con el segundo color. Este es un gris claro en respresentación de la tecnolgía, que a su vez también es el color de la carretera. El mismo aporta seriedad a la marca y complementa al rojo para comunicar seguridad y tecnología, logrando un gran contraste, lo cual hace que un color sea legible colocado sobre el otro.",
  },
  {
    itemTitle: "Logotipo y eslogan",
    itemDesc:
      "Para el logotipo y el eslogan se escogió la tipografía Hurme Geometric Sans 1 Bold, por ser sólida, geometrica y tener terminaciones puntiagudas al igual que el isotipo. Por su modernidad también se asocia a la tecnología y su peso y geometería comunican hermetismo y seguridad.",
  },
  {
    itemTitle: "Imagotipo",
    itemDesc:
      "Para el imagotipo en su versión principal se decidió colocar el isotipo del lado izquierdo usando el rojo corporativo y para el logotipo a su derecha el gris de la marca con el objetivo de destacar el isotipo. Los elementos se encuentran centrados horizontalmente de forma visual para lograr una composición equilibrada. El eslogan se coloca debajo del logotipo ocupando el mismo ancho que este.",
  },
  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Positivo una tinta",
    itemDesc:
      "Versión del logo sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.",
  },
  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Positivo varias tintas",
    itemDesc:
      "Versión del logo sobre fondos claros con mas de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.",
  },
  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Negativo una tinta",
    itemDesc:
      "Versión del logo sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco.",
  },
  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Negativo varias tintas",
    itemDesc:
      "Versión del logo sobre fondos oscuros con más de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, blanco #ffffff / papel blanco.",
  },
  {
    itemTitle: "Tipografía web y papelería",
    itemDesc:
      "Para uso en web y papelería de la marca se busco una tipografía moderna que fuese legible en todos los toportes y en tamaño pequeño. La elección fue Roboto, tipografía que cuenta con una dobe naturaleza, tiene un esqueleto mecánico y las formas son en gran parte geométricas. Al mismo tiempo, la fuente presenta curvas amigables y abiertas. Mientras que algunos grotescos distorsionan sus formas de letras para forzar un ritmo rígido, Roboto no se compromete, permitiendo que las letras se asienten en su ancho natural. Esto crea un ritmo de lectura más natural que se encuentra más comúnmente en los tipos humanistas y serif.",
  },
  {
    itemTitle: "Formas abstractas",
    itemDesc:
      "Como elemento complementario gráfico se decidió crear formas geométricas abstractas que acompañaran al isotipo en algunas aplicaciones y comunicaran la esencia de la marca. Estas son similares a caminos y a su vez a la forma del ADN, en representación de la información genética y del monitoreo durante el recorrido de los vacunos.",
  },
]


export const dataQuote = [
  {
    Text: "La gente ignora el diseño que ignora a la gente.",
    Autor: "Frank Chimero",
  },
]

